import request from '@/utils/request'


// 查询用户入驻申请列表
export function listEntryApply(query) {
  return request({
    url: '/user/user-entry-applys/list',
    method: 'get',
    params: query
  })
}

// 查询用户入驻申请分页
export function pageEntryApply(query) {
  return request({
    url: '/user/user-entry-applys/page',
    method: 'get',
    params: query
  })
}

// 查询用户入驻申请详细
export function getEntryApply(data) {
  return request({
    url: '/user/user-entry-applys/detail',
    method: 'get',
    params: data
  })
}

// 新增用户入驻申请
export function addEntryApply(data) {
  return request({
    url: '/user/user-entry-applys/add',
    method: 'post',
    data: data
  })
}

// 修改用户入驻申请
export function updateEntryApply(data) {
  return request({
    url: '/user/user-entry-applys/edit',
    method: 'post',
    data: data
  })
}

// 删除用户入驻申请
export function delEntryApply(data) {
  return request({
    url: '/user/user-entry-applys/delete',
    method: 'post',
    data: data
  })
}
